exports.components = {
  "component---src-news-chai-and-chat-jan-23-mdx": () => import("./../../../src/news/chai-and-chat-jan23.mdx" /* webpackChunkName: "component---src-news-chai-and-chat-jan-23-mdx" */),
  "component---src-news-chai-n-chat-mdx": () => import("./../../../src/news/ChaiNChat.mdx" /* webpackChunkName: "component---src-news-chai-n-chat-mdx" */),
  "component---src-news-december-22-mdx": () => import("./../../../src/news/December22.mdx" /* webpackChunkName: "component---src-news-december-22-mdx" */),
  "component---src-news-human-appeal-mdx": () => import("./../../../src/news/HumanAppeal.mdx" /* webpackChunkName: "component---src-news-human-appeal-mdx" */),
  "component---src-news-national-lottery-2023-mdx": () => import("./../../../src/news/national-lottery-2023.mdx" /* webpackChunkName: "component---src-news-national-lottery-2023-mdx" */),
  "component---src-news-november-22-donations-mdx": () => import("./../../../src/news/November22Donations.mdx" /* webpackChunkName: "component---src-news-november-22-donations-mdx" */),
  "component---src-news-qa-centre-marketing-mdx": () => import("./../../../src/news/qa_centre_marketing.mdx" /* webpackChunkName: "component---src-news-qa-centre-marketing-mdx" */),
  "component---src-news-qa-level-3-award-paediatric-first-aid-mdx": () => import("./../../../src/news/qa-level-3-award-paediatric-first-aid.mdx" /* webpackChunkName: "component---src-news-qa-level-3-award-paediatric-first-aid-mdx" */),
  "component---src-news-walk-5-k-mdx": () => import("./../../../src/news/Walk5K.mdx" /* webpackChunkName: "component---src-news-walk-5-k-mdx" */),
  "component---src-news-year-anniversary-mdx": () => import("./../../../src/news/yearAnniversary.mdx" /* webpackChunkName: "component---src-news-year-anniversary-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-k-js": () => import("./../../../src/pages/5k.js" /* webpackChunkName: "component---src-pages-5-k-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-events-2021-5-k-js": () => import("./../../../src/pages/events/2021/5k.js" /* webpackChunkName: "component---src-pages-events-2021-5-k-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */)
}

